import { Link } from "react-router-dom";
import { useAuth } from "../store";

const SigninBanner = () => {
  const isLoggedIn = useAuth((state) => state.isLoggedIn);
  return isLoggedIn ? (
    <></>
  ) : (
    <div className="signin-banner flex flex-col gap-1 justify-center items-center py-3.5">
      <hr className="h-6 w-full" />
      <span className="text-sm">See personalized recommendations</span>
      <Link
        className="signin-btn flex justify-center text-xs font-semibold bg-primary-1 active:bg-primary-0 border border-primary-0 opacity-95 rounded min-w-24 w-1/5 p-2"
        to={"/auth/"}
      >
        Sign in
      </Link>
      <span className="text-xs">
        New customer?{" "}
        <Link
          to={"#"}
          className="text-primary-6 hover:text-primary-3 hover:underline"
        >
          Start here.
        </Link>
      </span>
      <hr className="h-6 w-full mt-4" />
    </div>
  );
};
export default SigninBanner;
