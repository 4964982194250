import { useState } from "react";
import CategoryNavbar from "../Components/CategoryNavbar";
import SigninBanner from "../Components/SigninBanner";
import SiteFooter from "../Components/SiteFooter";
import MainFooter from "../Components/MainFooter";
import MainNavbar from "../Components/MainNavbar";
import Sidebar from "./Sidebar";
import { ProductsData } from "../DataStore/ProductsData.js";
import ProductBar from "./ProductBar";
import { useParams } from "react-router-dom";

const SearchPage = () => {
  const { searchData } = useParams();
  const productData = ProductsData.filter((product) =>
    (
      product["Product Name"] +
      product["About Product"] +
      product["Category"] +
      product["Model Number"] +
      product["Selling Price"].substring(1) +
      product["Shipping Weight"]
    )
      .toLowerCase()
      .includes(
        !searchData || searchData === "all" ? "" : searchData.toLowerCase()
      )
  );
  const [pageNo, setPageNo] = useState(1);
  const itemsPerPage = 20;
  const maxPage = productData.length / itemsPerPage;
  return (
    <div className="search-page bg-primary-4-xxx vh-100 w-full max-w-screen overflow-x-hidden max-sm:hidden">
      <MainNavbar />
      <CategoryNavbar />
      <div className="flex gap-2 pb-12">
        <Sidebar />
        <div className="flex flex-col gap-2.5 w-full p-2">
          <div className="flex flex-col">
            <span className="text-lg font-semibold">Results</span>
            <span className="text-sm">
              Check each product page for other buying options.
            </span>
          </div>
          {productData
            ?.slice(pageNo * itemsPerPage - itemsPerPage, pageNo * itemsPerPage)
            ?.map((product,index) => (
              <ProductBar key={"product-bar-"+index} product={product} />
            ))}
          <div className="pagination flex justify-center items-center p-2.5">
            <div className="flex justify-center border-2 rounded-md border-primary-5 w-fit">
              {pageNo > 1 ? (
                <button
                  className="hover:bg-bw-7 p-2.5"
                  onClick={() => setPageNo(pageNo - 1)}
                >
                  Previous
                </button>
              ) : (
                <button className="opacity-75 p-2.5" disabled>
                  Previous
                </button>
              )}
              {Array.from({ length: maxPage }, (_, index) => {
                return index === 0 ||
                  (index <= 2 && pageNo <= 2) ||
                  index === pageNo ||
                  index + 1 === pageNo ||
                  index + 2 === pageNo ||
                  (index >= maxPage - 3 && pageNo >= maxPage - 2) ||
                  index === maxPage - 1 ? (
                  <div
                    className={`cursor-pointer flex justify-center ${
                      pageNo === index + 1 ? "outline" : "hover:bg-bw-7"
                    } outline-1 w-12 p-2.5`}
                    onClick={() => setPageNo(index + 1)}
                  >
                    {index + 1}
                  </div>
                ) : (
                  ""
                );
              })}
              {pageNo < maxPage ? (
                <button
                  className="hover:bg-bw-7 p-2.5"
                  onClick={() => setPageNo(pageNo + 1)}
                >
                  Next
                </button>
              ) : (
                <button className="opacity-75 p-2.5" disabled>
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <SigninBanner />
      <SiteFooter />
      <MainFooter />
    </div>
  );
};

export default SearchPage;
