// ----------------------------------------------------------------- //

//

// --------------- --- -- - Search Page Data - -- --- -------------- //

//

// ----------------------------------------------------------------- //

export const OptionMenusList = [
  {
    title: "Brands",
    options: [
      "OnePlus",
      "Samsung",
      "realme",
      "Redmi",
      "Xiaomi",
      "POCO",
      "Nokia",
      "Motorola",
      "iQOO",
      "Oppo",
      "Vivo",
      "Lava",
      "Honor",
      "IKALL",
      "Tecno",
      "Nothing",
      "KECHAODA",
      "Itel",
      "MI",
      "Nillkin",
      "Infinix",
      "SHIVANSH",
      "TIJAS",
    ],
  },
  {
    title: "Cellular Phone Memory Storage Capacity",
    options: [
      "Up to 3.9 GB",
      "4 GB",
      "8 GB",
      "16 GB",
      "32 GB",
      "64 GB",
      "128 GB",
      "256 GB",
      "512 GB & above",
    ],
  },
  { title: "Pay On Delivery", options: ["Eligible for Pay On Delivery"] },
  {
    title: "Processor Speed",
    options: [
      "Less Than 0.99 GHz",
      "1 - 1.49 GHz",
      "1.5 - 1.99 GHz",
      "2 - 2.4 GHz",
      "2.5 GHz & Above",
    ],
  },
  {
    title: "Battery Capacity",
    options: [
      "Up to 2,999 mAh",
      "3,000 to 3,999 mAh",
      "4,000 to 4,999 mAh",
      "5,000 to 5,999 mAh",
      "6,000 mAh & Above",
    ],
  },
  {
    title: "Mobile Phone Primary Camera Resolution",
    options: [
      "Up to 3.9 MP",
      "4 - 7.9 MP",
      "8 - 11.9 MP",
      "12 - 15.9 MP",
      "16 - 19.9 MP",
      "32 MP & Above",
    ],
  },
  { title: "Lens Type", options: ["Macro", "Ultra Wide", "Wide Angle"] },
  {
    title: "Cellular Phone Battery Average Life",
    options: ["Up to 6.9 h", "7 to 12.9 h", "13 to 18.9 h", "19 h & above"],
  },
  {
    title: "Mobile Phone Resolution",
    options: [
      "1080 x 2340",
      "1080 x 2400",
      "1280 x 720",
      "1520 x 720",
      "1600 x 720",
      "2280 x 1080",
      "2340 x 1080",
      "240 x 320",
      "2400 x 1080",
      "720 x 1280",
      "720 x 1440",
      "720 x 1520",
      "720 x 1600",
      "960 x 540",
    ],
  },
  {
    title: "Refresh Rate",
    options: ["Up to 88 Hz", "89 to 104 Hz", "105 to 120 Hz", "121 Hz & above"],
  },
  {
    title: "Mobile Phone Installed RAM Size",
    options: [
      "Up to 1.9 GB",
      "2 to 3.9 GB",
      "4 to 5.9 GB",
      "6 to 7.9 GB",
      "8 to 9.9 GB",
      "10 GB & Above",
    ],
  },
  {
    title: "Front Photo Sensor Resolution",
    options: ["Up to 6.9 MP", "7.0 to 9.9 MP", "13.0 MP & above"],
  },
  {
    title: "Mobile Phone Shooting Modes",
    options: [
      "Aperture Priority",
      "Automatic",
      "High Dynamic Range",
      "Macro",
      "Manual",
      "Night Mode",
      "Panorama",
      "Portrait",
      "Scene",
      "Sports",
      "Underwater",
    ],
  },
  { title: "SIM Card Slot Count", options: ["Single SIM", "Dual SIM"] },
  {
    title: "Device Features",
    options: [
      "Bluetooth Tethering",
      "Dual SIM",
      "E-Mail",
      "GPS",
      "Hotspot",
      "Internet",
      "MP3",
      "Music Player",
      "Primary Camera",
      "Radio",
      "Secondary Camera",
      "Touchscreen",
      "USB",
      "WAP",
    ],
  },
  { title: "Display Type", options: ["AMOLED", "LCD", "OLED"] },
  {
    title: "Screen Size",
    options: ["Up to 3.9 in", "4.5 to 4.9 in", "5 to 5.4 in", "5.5 in & above"],
  },
  {
    title: "Mobile Phone Connectivity Technology",
    options: ["Bluetooth", "Infrared", "NFC", "USB", "Wi-Fi"],
  },
  {
    title: "Cell Phone Connector Type",
    options: ["3.5mm Jack", "Micro USB", "Mini USB", "USB Type C"],
  },
  {
    title: "Mobile Phone Biometric Security Feature",
    options: ["Face Recognition", "Fingerprint Recognition"],
  },
  {
    title: "Included Components",
    options: [
      "Phone Case",
      "Power Adapter",
      "SIM Tray Ejector",
      "USB Cable",
      "Screen Protector",
    ],
  },
  {
    title: "Item Weight",
    options: [
      "Up to 141.9 g",
      "142 to 197.9 g",
      "198 to 254.9 g",
      "255 g & Above",
    ],
  },
  { title: "Video Capture Resolution", options: ["1080p", "4k", "720p", "8k"] },
  { title: "Cell Phone Brands", options: ["Top Brands"] },
  {
    title: "Cellular Phone Photo Sensor Resolution",
    options: [
      "Up to 9.9 MP",
      "10 to 12.9 MP",
      "13 to 15.9 MP",
      "16 MP & Above",
    ],
  },
  {
    title: "Form Factor",
    options: ["Bar", "Foldable Case", "Slate", "Slider"],
  },
  {
    title: "Mobile Phone Human Interface Input",
    options: [
      "Buttons",
      "OCR",
      "Keyboard",
      "Microphone",
      "Numeric Keypad",
      "Touchscreen",
      "Touchscreen with Stylus Support",
    ],
  },
  {
    title: "Operating System",
    options: [
      "Android 10.0",
      "Android 11.0",
      "Android 12.0",
      "Android 13.0",
      "Android 9.0",
      "Android 4.1",
      "Android 6.0",
      "Android 7.0",
      "Android 7.1",
      "Android 8.0",
      "Android 8.1",
      "iOS 13",
      "MIUI 10",
      "MIUI 12",
      "MIUI 12.5",
      "OxygenOS",
    ],
  },
  { title: "Sim Card Size", options: ["Nano"] },
  { title: "Cellular Technology", options: ["2G", "3G", "4G", "5G"] },
  {
    title: "Seller",
    options: [
      "Daily Shoppers",
      "Appario Retail Private Ltd",
      "STPL Exclusive Online",
      "Livemate",
      "TIMES INTERNATIONAL",
      "The Renew Store",
      "Darshita Etel",
    ],
  },
  { title: "Availability", options: ["Include Out of Stock"] },
];

export const LinksMenusList = [
  {
    title: "Prices",
    links: ["All Discounts", "Today's Deals"],
  },

  { title: "New Arrivals", links: ["Last 30 days", "Last 90 days"] },
  { title: "Item Condition", links: ["New", "Renewed"] },
  {
    title: "Discount",
    links: [
      "10% Off or more",
      "25% Off or more",
      "35% Off or more",
      "50% Off or more",
      "60% Off or more",
      "70% Off or more",
    ],
  },
];
