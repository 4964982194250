import { Link } from "react-router-dom";
import StarRating from "../Components/StarRating";

const ProductBar = ({ product }) => {
  return (
    <Link
      className="cursor-pointer flex items-center border border-primary-4 rounded w-full"
      to={`../product/${product["Uniq Id"]}`}
    >
      <div className="flex justify-center bg-bw-2 xl:min-w-[17.5rem] xl:max-w-[17.5rem] md:min-w-[14.25rem] md:max-w-[14.25rem] h-full">
        <img
          className="h-[13.625rem] object-contain"
          src={product["Image"][0]}
          alt="product"
        />
      </div>
      <div className="flex flex-col gap-1.5 p-3">
        <span className="text-lg font-medium">
          {product["Product Name"].length > 150
            ? product["Product Name"].substring(0, 150) + "..."
            : product["Product Name"]}
        </span>
        <div className="text-sm stars flex items-center gap-1">
          <StarRating rating={product["Rating"]} height={4} />
          <img
            width={8}
            src={`https://img.icons8.com/ios/8/000000/expand-arrow.png`}
            alt="expand arrow"
          />
          <span>{product["Review Count"]}</span>
        </div>
        <div className="flex text-sm items-end py-2">
          <span className="text-sm self-start leading-none">₹</span>
          <span className="text-3xl leading-none">{product["New Price"]}</span>
          <span className="opacity-80 leading-none">
            M.R.P: <s>₹{product["Old Price"]}</s>
          </span>
          <span className="leading-none">({product["Discount"]}% off)</span>
        </div>
        <img
          className="h-4 w-fit"
          src="https://m.media-amazon.com/images/G/31/perc/prime-logo.svg"
          alt="prime verified"
        />
        <span className="text-xs">
          FREE delivery <b>Sat, 6 Apr</b>
        </span>
        <span className="text-xs">Service: Installation</span>
        <span className="text-xs">
          Or fastest delivery <b>Tomorrow, 2 Apr</b>
        </span>
        <span
          className={`text-xs ${product["stock"] <= 5 && "text-primary-3"}`}
        >
          {product["stock"] <= 10 && "Only"} {product["stock"]} left in stock.
        </span>
        {product["Variants"] && (
          <Link className="text-xs text-primary-6 underline py-2.5 " to={"./"}>
            +{product["Variants"].length} colors/patterns/variants
          </Link>
        )}
      </div>
    </Link>
  );
};
export default ProductBar;
