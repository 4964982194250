import { LinksMenusList, OptionMenusList } from "../DataStore/SearchData";
import LinksMenu from "./LinksMenu";
import OptionMenu from "./OptionMenu";

const Sidebar = () => {
  return (
    <div className="flex flex-col gap-2.5 p-3.5">
      <OptionMenu menu={OptionMenusList[0]} />
      <div className="price-range-input flex flex-col text-sm w-64 gap-px">
        <span className="font-semibold py-0.5">Price</span>
        {prices.map((item,i) => (
          <span key={"search-price-option-"+(i+1)} className="cursor-pointer">{item}</span>
        ))}
        <div className="flex text-xs gap-1 py-1">
          <input
            className="border border-primary-7 rounded w-14 px-2 py-1.5"
            placeholder="₹ Min"
          />
          <input
            className="border border-primary-7 rounded w-14 px-2 py-1.5"
            placeholder="₹ Max"
          />
          <button className="border border-primary-7 rounded-lg py-1 px-3 mx-0.5">
            Go
          </button>
        </div>
      </div>
      <LinksMenu menu={LinksMenusList[0]} />
      {OptionMenusList.slice(1, 12).map((menu, i) => (
        <OptionMenu key={"1-to-11-options-menu-box-"+(i+1)} menu={menu} />
      ))}
      {/* colors */}
      {OptionMenusList.slice(12, 30).map((menu, i) => (
        <OptionMenu key={"12-to-29-options-menu-box-"+(i+1)} menu={menu} />
      ))}
      {LinksMenusList.slice(1, 4).map((menu, i) => (
        <LinksMenu key={"1-to-3-links-menu-box-"+(i+1)} menu={menu} />
      ))}
      {OptionMenusList.slice(30, 32).map((menu, i) => (
        <OptionMenu key={"30-to-31-options-menu-box-"+(i+1)} menu={menu} />
      ))}
    </div>
  );
};
export default Sidebar;

const prices = [
  "Under ₹1,000",
  "₹1,000 - ₹5,000",
  "₹5,000 - ₹10,000",
  "₹10,000 - ₹20,000",
  "Over ₹20,000",
];
