import SiteFooter from "../Components/SiteFooter";
import PosterCollection from "../Components/PosterCollection";
import MainFooter from "../Components/MainFooter";
import SigninBanner from "../Components/SigninBanner";
import CategoryNavbar from "../Components/CategoryNavbar";
import ProductsFlow from "../Components/ProductsRow";
import MainNavbar from "../Components/MainNavbar";
import Carousel from "./Carousel";
import TodaysDeals from "./TodaysDeals";
import {
  HomePostersData,
  HomePostersRow1,
  HomePostersRow2,
  HomePostersRow3,
  productData,
} from "../DataStore/HomeData.js";


const Home = () => {
  return (
    <div className="home bg-primary-4 vh-100 w-full max-w-screen overflow-x-hidden max-sm:hidden">
      <MainNavbar />
      <CategoryNavbar />
      <div className="2xl:container relative main mx-auto h-full">
        <Carousel />
        <div className="content-section relative pt-64 xl:top-56-x top-48-x p-5 z-10">
          <PosterCollection data={HomePostersData} />
          <TodaysDeals />
          <ProductsFlow products={productData[0]} />
          <ProductsFlow products={productData[1]} />
          <PosterCollection data={HomePostersRow1} />
          <ProductsFlow products={productData[2]} />
          <ProductsFlow products={productData[3]} />
          <PosterCollection data={HomePostersRow2} />
          <ProductsFlow products={productData[4]} />
          <PosterCollection data={HomePostersRow3} />
        </div>
      </div>
      <SigninBanner />
      <SiteFooter />
      <MainFooter />
    </div>
  );
};
export default Home;
