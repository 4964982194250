import { useState } from "react";
import CategoryNavbar from "../Components/CategoryNavbar";
import MainNavbar from "../Components/MainNavbar";
import SiteFooter from "../Components/SiteFooter";
import SigninBanner from "../Components/SigninBanner";
import StarRating from "../Components/StarRating";
import MainFooter from "../Components/MainFooter";
import { useCart } from "../store";
import { getProduct } from "../DataStore/ProductsData.js";

import { Link, useParams } from "react-router-dom";

const ProductPage = () => {
  const { productId } = useParams();
  const product = getProduct(productId);
  const [quantity, setQuantity] = useState(1);
  const [productImageIndex, setProductImageIndex] = useState(0);
  const { addToCart } = useCart();
  const discountedPrice = product ? product["New Price"] : null;
  return (
    <div className="cursor-pointer flex flex-col h-full w-full max-w-screen overflow-x-hidden">
      <MainNavbar />
      <CategoryNavbar />
      {product ? (
        <>
          <div className="p-1.5">
            <Link
              className="text-xs border-primary-6 rounded hover:border py-1 px-2"
              to={"/search/all"}
            >
              {" ← go back to search page"}
            </Link>
          </div>
          <div className="flex px-4 py-5">
            {/* Product Image(s) */}

            <div className="flex bg-bw-2-xxx bg-[#f0f0f0]=xxx h-min divide-x divide-black gap-0.5 p-2">
              <div className="flex flex-col gap-3.5 p-2">
                {product["Image"]?.map((image, index) =>
                  index !== product["Image"].length - 1 ? (
                    <div
                      key={product["Uniq Id"] + "'s-product-image-" + index}
                      className="border border-[#017184] outline outline-4 outline-[#c8f3fa] rounded-xl w-12 h-12 p-0.5"
                      onClick={() => setProductImageIndex(index)}
                    >
                      <img
                        className="h-full object-cover rounded-lg"
                        src={image}
                        alt={product["Product Name"] + "product"}
                      />
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </div>
              <div className="flex items-center min-w-96 ps-1">
                <img
                  className="object-contain w-min flex-1"
                  src={product["Image"][productImageIndex]}
                  alt="product"
                />
              </div>
            </div>

            {/* Product Details */}

            <div className="flex flex-col py-1 px-8">
              <span className="text-3xl font-medium">
                {product["Product Name"]}
              </span>
              <span className="text-sm text-primary-6 hover:underline hover:text-primary-1">
                Visit the Store
              </span>
              <div className="text-sm stars flex items-center gap-1">
                <span>{product["Rating"]}</span>
                <StarRating rating={product["Rating"]} height={4} />
                <img
                  width={8}
                  src={`https://img.icons8.com/ios/8/000000/expand-arrow.png`}
                  alt="expand arrow"
                />
              </div>
              <span className="text-xs font-semibold">
                <span className="text-green-600">
                  {Math.ceil(product["Stock"] / 10) * 10}+
                </span>{" "}
                bought in past month
              </span>
              <hr className="my-2.5" />
              <div className="flex text-sm items-end gap-1.5 py-2">
                <span className="text-3xl font-thin text-primary-3 leading-none">
                  -{Math.ceil(product["Discount"])}%
                </span>
                <div className="flex items-end">
                  <span className="text-sm self-start leading-none">₹</span>
                  <span className="text-3xl leading-none">
                    {discountedPrice}
                  </span>
                </div>
              </div>
              <div className="flex text-sm items-end gap-1.5 py-2 pb-10">
                <span className="opacity-80 leading-none">
                  M.R.P: <s>₹{product["Old Price"]}</s>
                </span>
              </div>
              <hr className="my-2.5" />
              {product["About Product"] && (
                <div className="flex flex-col flex-wrap text-wrap leading-none gap-2 py-2.5">
                  <span className="text-lg font-semibold">About Product</span>
                  <ul className="text-sm flex flex-col gap-0.5">
                    {product["About Product"].split("|").map((line, i) => (
                      <li key={"about-product-" + line + i}>
                        {"\u2023 " + line.trim()}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <hr className="my-2.5" />
              {product["Technical Details"] && (
                <div className="flex flex-col flex-wrap text-wrap leading-none gap-2 py-2.5">
                  <span className="text-lg font-semibold">
                    Technical Details
                  </span>
                  <div className="text-sm flex flex-col gap-0.5">
                    {product["Technical Details"].split("|").map((line, i) => (
                      <span key={"technical-detail-" + line + i}>
                        {"\u2023 " + line.trim()}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Product Pricing */}

            <div className="flex flex-col border min-w-72 p-4">
              <div className="flex text-sm items-end py-2">
                <span className="text-sm self-start leading-none">₹</span>
                <span className="text-3xl leading-none">
                  {discountedPrice.substring(0, discountedPrice.length - 3)}
                </span>
                <span className="text-sm self-start leading-none">
                  {discountedPrice.substring(discountedPrice.length - 2)}
                </span>
              </div>
              <div className="flex flex-col text-sm py-2">
                <span className="text-xs font-semibold">
                  <span className="text-primary-6">FREE delivery</span> Sat, 6
                  Apr
                </span>
                <span className="text-xs">
                  <span className="text-primary-6">Service</span>: Installation
                </span>
                <span className="text-xs">
                  Or fastest delivery <b>Tomorrow, 2 Apr</b>
                </span>
                <span
                  className={`text-xs ${
                    product["Stock"] <= 5 && "text-primary-3"
                  }`}
                >
                  {product["Stock"] <= 10 && "Only"} {product["Stock"]} left in
                  stock.
                </span>
              </div>
              <div className="cursor-pointer flex items-end gap-2">
                <img
                  src="https://img.icons8.com/fluency-systems-regular/48/000000/marker--v1.png"
                  className="py-1"
                  alt="Location Icon"
                  height={26}
                  width={26}
                />
                <div className="flex flex-col text-xs font-medium text-primary-6 justify-center">
                  <span>Delivering to Dehradun 248001</span>
                  <span>Update location</span>
                </div>
              </div>
              <span className="text-lg text-green-600">In stock</span>
              <div className="flex flex-col gap-2.5">
                <div className="flex gap-2">
                  <span>Quantity:</span>
                  <select
                    id="product-quantity"
                    className="border border-bw-1 rounded w-fit px-2.5"
                    defaultValue={1}
                    onChange={(e) => setQuantity(e.target.value)}
                  >
                    {Array.from({ length: 9 }, (_, index) => (
                      <option value={index + 1}>{index + 1}</option>
                    ))}
                  </select>
                </div>
                <button
                  className="text-sm bg-[#ffd812] active:bg-primary-1 rounded-full p-1.5"
                  onClick={() =>
                    addToCart({
                      product: { "Uniq Id": product["Uniq Id"] },
                      quantity: quantity,
                    })
                  }
                >
                  Add to Cart
                </button>
                <button
                  className="text-sm bg-primary-1 active:bg-primary-0 rounded-full p-1.5"
                  onClick={() =>
                    addToCart({
                      product: { "Uniq Id": product["Uniq Id"] },
                      quantity: quantity,
                    })
                  }
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center flex-1 h-full w-full p-16">
          <span>Item Not Found!</span>{" "}
          <Link className="text-primary-6" to={"/search/all"}>
            go back to search
          </Link>
        </div>
      )}
      <SigninBanner />
      <SiteFooter />
      <MainFooter />
    </div>
  );
};

export default ProductPage;
