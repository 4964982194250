import { TodaysDealsData } from "../DataStore/HomeData";

const TodaysDeals = () => {
  return (
    <div className="todays-deals flex flex-col bg-white gap-2.5 p-5 my-5">
      <div className="flex items-end gap-4">
        <span className="text-xl font-semibold">Today’s Deals</span>
        <span className="cursor-pointer text-sm text-primary-6 hover:text-red-600 hover:underline">
          See all deals
        </span>
      </div>
      <div className="text-xs flex overflow-x-scroll overflow-y-hidden gap-2">
        {TodaysDealsData.map((card) => (
          <div
            key={"deal-box-for-" + card.detail}
            className="todays-deal-card flex flex-col gap-1.5"
          >
            <div className="todays-deal-card-image flex justify-center p-2.5 mb-0.5">
              <img src={card.img} alt={card.detail} />
            </div>
            <div className="font-semibold flex items-center gap-1.5">
              <span className="text-white bg-primary-3 rounded-sm px-1.5 py-1">
                {card.discount}
              </span>
              <span className="text-primary-3 ">Limited time deal</span>
            </div>
            {card.price && (
              <div className="todays-deal-card-price flex items-end gap-1">
                <span className="text-lg leading-5">{card.price.new}</span>
                <span className="text-gray-600">
                  M.R.P.: <s>{card.price.old}</s>
                </span>
              </div>
            )}
            <span>
              {card.detail.length > 36
                ? card.detail.substring(0, 36) + "..."
                : card.detail}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default TodaysDeals;
