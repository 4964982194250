import CategoryNavbar from "../Components/CategoryNavbar";
import MainNavbar from "../Components/MainNavbar";
import SiteFooter from "../Components/SiteFooter";
import SigninBanner from "../Components/SigninBanner";
import MainFooter from "../Components/MainFooter";
import { useCart } from "../store";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CartProduct from "./CartProduct.js";
import { TodaysDealsData } from "../DataStore/HomeData.js";
import EmiOptionsBtn from "./EmiOptionsBtn.js";
import { getProduct } from "../DataStore/ProductsData.js";

const CartPage = () => {
  const { cart, totalItems, resetCart } = useCart();
  const [cartTotalAmount, setCartTotalAmount] = useState(0); 
  useEffect(() => {
    let total = 0;
    cart?.forEach(
      (pro) => {
        const product = getProduct(pro.product["Uniq Id"]);
        total += product
          ? parseFloat(product["New Price"].replace(/,/g, "")) * pro.quantity
          : 0;
      }, 
      [cart]
    );
    setCartTotalAmount(
      (total.toFixed(2) + "").replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    );
  }, [cart]);

  console.log(cart);

  return (
    <div className="bg-[#eaeded] flex flex-col h-full w-full max-w-screen overflow-x-hidden">
      <MainNavbar />
      <CategoryNavbar />
      <div className="flex">
        {totalItems ? (
          <div className="flex-1 flex gap-4 p-4">
            <div className="cart-content flex-1 flex flex-col bg-white rounded p-5 m-0.5">
              <span className="text-3xl">Shopping Cart</span>
              <div className="flex gap-1">
                <span
                  className="cursor-pointer text-sm text-primary-6 border-2 border-transparent hover:border-primary-6 w-fit px-1 mb-3"
                  onClick={resetCart}
                >
                  {/* Deselect all items | */} Empty Cart
                </span>
              </div>
              {cart?.map((product, i) => (
                <div
                  key={
                    `box ${i + 1} for product :` +
                    JSON.stringify(product).trim()
                  }
                  className="items-start justify-start p-0 m-0"
                >
                  <hr className="mx-1 my-0.5" />
                  <CartProduct product={product} />
                </div>
              ))}
            </div>
            <div className="flex flex-col max-w-80 gap-4">
              {/*  */}
              <div className="flex flex-col min-w-64 bg-white rounded gap-4 px-5 pt-6 pb-8 m-0.5">
                <div className="flex items-start gap-1">
                  <img
                    src="https://img.icons8.com/glyph-neue/24/0a7e62/tiktok-verified-account.png"
                    alt="verified-icon"
                  />
                  <span className="text-xs">
                    <span className="text-green-700">
                      Part of your order qualifies for FREE Delivery.
                    </span>
                    Choose FREE Delivery option at checkout.
                  </span>
                </div>
                <div className="flex flex-col gap-1.5">
                  <div className="flex text-sm flex-wrap gap-1">
                    <span className="text-xl leading-none">
                      Subtotal ({totalItems} items):
                    </span>
                    <div className="flex text-nowrap items-end ">
                      <span className="text-sm self-start leading-none">₹</span>
                      <span className="text-xl font-semibold leading-none">
                        {cartTotalAmount}
                      </span>
                    </div>
                  </div>
                  <div className="flex text-sm items-center gap-1 px-0.5">
                    <input
                      id={"option-at-checkout"}
                      type="checkbox"
                      className="cursor-pointer"
                    />
                    <label
                      htmlFor={"option-at-checkout"}
                      className="cursor-pointer"
                    >
                      This order contains a gift
                    </label>
                  </div>
                </div>
                <button className="text-sm bg-[#ffd812] active:bg-primary-0 rounded-lg p-1.5">
                  Proceed to Buy
                </button>
                <EmiOptionsBtn />
              </div>
              <div className="flex flex-col min-w-64 bg-white rounded gap-4 p-5 m-0.5">
                <span className="text-lg font-semibold text-nowrap leading-none">
                  Pair with your cart
                </span>
                <div className="flex flex-col gap-3">
                  {TodaysDealsData.map((item, index) =>
                    (totalItems < 3 && index < totalItems) ||
                    (totalItems >= 3 && totalItems <= 12 && index < 5) ||
                    totalItems > 12 ? (
                      <div
                        key={"today's deal number - " + (index + 1)}
                        className="flex flex-col items-center border rounded p-4"
                      >
                        <div className="flex min-w-44 w-44 max-w-44 min-h-44 h-44 max-h-44 gap-0.5 p-1.5">
                          <img
                            className="object-contain rounded w-full h-full flex-1"
                            src={item.img}
                            alt={item.detail}
                          />
                        </div>
                        <span className="text-xs font-semibold">
                          {item.detail}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center flex-1 border-b border-primary-6 h-full w-full p-14">
            <div className="flex flex-col items-center border shadow-lg rounded-2xl bg-white border-primary-6 p-12 gap-2">
              <span className="text-lg">Your Cart Is Empty!</span>{" "}
              <Link className="text-primary-6 border-b border-gray-400 p-0" to={"/search/all"}>
                ← go back to search
              </Link>
            </div>
          </div>
        )}
      </div>
      <SigninBanner />
      <SiteFooter />
      <MainFooter />
    </div>
  );
};

export default CartPage;
