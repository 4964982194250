import { Link } from "react-router-dom";

const LinksMenu = ({ menu }) => {
  return (
    <div className="flex flex-col text-sm w-64 gap-0.5">
      <span className="font-semibold py-0.5">{menu?.title}</span>
      {menu?.links?.map((item,i) => (
        <Link key={"item-"+item+"-"+i} to={"./"} className="cursor-pointer hover:text-primary-3">
          {item}
        </Link>
      ))}
    </div>
  );
};
export default LinksMenu;
