// ---------------- Categories Data ---------------------------- //

export const HomeCategories = [
  "PsiaceStore miniTV",
  "Sell",
  "Best Sellers",
  "Mobiles",
  "Today's Deals",
  "Customer Service",
  "Prime",
  "Electronics",
  "New Releases",
  "Home & Kitchen",
  "Gift Ideas",
  "PsiaceStore Pay",
  "Fashion",
  "Computers",
  "Books",
  //   "Toys & Games",
  //   "Home Improvement",
];

// -----------------------Site Footer Data---------------------------- //

export const SiteFooterData = [
  {
    header: "Get to Know Us",
    links: [
      { title: "About Us", href: "./" },
      { title: "Careers", href: "./" },
      { title: "Press Releases", href: "./" },
      { title: "PsiaceStore Science", href: "./" },
    ],
  },
  {
    header: "Connect with Us",
    links: [
      { title: "Facebook", href: "./" },
      { title: "Twitter", href: "./" },
      { title: "Instagram", href: "./" },
    ],
  },
  {
    header: "Make Money with Us",
    links: [
      { title: "Sell on PsiaceStore", href: "./" },
      { title: "Sell under PsiaceStore Accelerator", href: "./" },
      { title: "Protect and Build Your Brand", href: "./" },
      { title: "PsiaceStore Global Selling", href: "./" },
      { title: "Become an Affiliate", href: "./" },
      { title: "Fulfilment by PsiaceStore", href: "./" },
      { title: "Advertise Your Products", href: "./" },
      { title: "PsiaceStore Pay on Merchants", href: "./" },
    ],
  },
  {
    header: "Let Us Help You",
    links: [
      { title: "COVID-19 and PsiaceStore", href: "./" },
      { title: "Your Account", href: "./" },
      { title: "Returns Centre", href: "./" },
      { title: "100% Purchase Protection", href: "./" },
      { title: "PsiaceStore App Download", href: "./" },
      { title: "Help", href: "./" },
    ],
  },
];

export const SiteFooterLanguagesData = [
  "Australia",
  "Brazil",
  "Canada",
  "China",
  "France",
  "Germany",
  "Italy",
  "Japan",
  "Mexico",
  "Netherlands",
  "Poland",
  "Singapore",
  "Spain",
  "Turkey",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
]

// ----------------------------------------------------------------- //

export const MainFooterData = [
  ["AbeBooks", "Books, art", "& collectibles"],
  ["PsiaceStore Web Services", "Scalable Cloud", "Computing Services"],
  ["Audible", "Download", "Audio Books"],
  ["IMDb", "Movies, TV", "& Celebrities"],
  ["Shopbop", "Designer", "Fashion Brands"],
  ["PsiaceStore Business", "Everything For", "Your Business"],
  ["Prime Now", "2-Hour Delivery", "on Everyday Items"],
  [
    "PsiaceStore Prime Music",
    "100 million songs, ad-free",
    "Over 15 million podcast episodes",
  ],
];
