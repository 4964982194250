import { useState } from "react";
import { useAuth, useCart } from "../store";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";

const MainNavbar = () => {
  const [localSearchData, setLocalSearchData] = useState(""); // (localStorage.getItem("userSearchData") || "");
  const { isLoggedIn, logout } = useAuth();
  const { totalItems } = useCart();
  const [accountExpanded, setAccountExpanded] = useState(false);
  const navigate = useNavigate();
  function search(event) {
    event.preventDefault();
    if (localSearchData) navigate("/search/" + localSearchData);
    else console.log("Enter Something to search!");
    setLocalSearchData("");
  }
  return (
    <div className="main-navbar sticky top-0 flex gap-x-6 text-white items-center text-nowrap bg-primary-8 w-full px-6 py-3 z-30">
      <Link
        className="cursor-pointer flex max-md:hidden amazon-logo items-start min-w-24"
        to={"/"}
      >
        <img
          src="https://pngimg.com/uploads/amazon/amazon_PNG11.png"
          alt="Amazon Logo"
        />
        <span>.in</span>
      </Link>
      <div className="cursor-pointer flex max-lg:hidden items-end ps-4 pe-2">
        <img
          src="https://img.icons8.com/fluency-systems-regular/48/ffffff/marker--v1.png"
          className="py-1"
          alt="Location Icon"
          height={20}
          width={20}
        />
        <div className="flex flex-col justify-center">
          <span className="text-xs font-extralight">
            Delivering to Dehradun 248001
          </span>
          <span className="text-sm font-bold">Update location</span>
        </div>
      </div>
      <form
        className="flex flex-1 overflow-hidden border-2 border-transparent rounded text-black hover:border-primary-1 focus:border-primary-1 min-w-80 h-10"
        onSubmit={search}
      >
        <div className="cursor-pointer flex relative flex border-e border-gray-300">
          <select
            className="items-center text-sm gap-2 text-black bg-primary-5 outline-0 w-fit py-2 ps-3 pe-5"
            defaultValue={"All"}
          >
            <option className="w-full py-4">All</option>
          </select>
          <img
            className="absolute right-1 py-3.5"
            src="https://img.icons8.com/ios-filled/8/000000/sort-down.png"
            alt="dropdown arrow"
          />
        </div>
        <input
          type="text"
          className="search-box flex-1 px-2 outline-0 py-1.5"
          placeholder="Search psiacestore.eshank.biz"
          value={localSearchData}
          onChange={(event) => {
            setLocalSearchData(event.target.value);
            // localStorage.setItem("userSearchData", event.target.value);
          }}
        />
        <button
          type="submit"
          className="cursor-pointer search-btn bg-primary-0 hover:bg-primary-1 px-3 py-2"
        >
          <img
            src="https://img.icons8.com/ios-glyphs/20/search--v1.png"
            alt="Search Icon"
          />
        </button>
      </form>
      <div className="cursor-pointer flex max-lg:hidden align-self-center items-center gap-x-1">
        <img
          src="https://img.icons8.com/color/48/india.png"
          height={18}
          width={18}
          alt="Country Flag"
        />
        <span className="text-sm font-bold">EN</span>
        <img
          className="py-1.5"
          src="https://img.icons8.com/ios-filled/10/a7acb2/sort-down.png"
          alt="dropdown arrow"
        />
      </div>
      <div className="cursor-pointer flex items-end gap-x-1">
        <div className="relative flex flex-col items-center">
          <div
            className="absolute w-40 h-28 scale-150"
            onPointerLeave={() => setAccountExpanded(false)}
          >
            {" "}
          </div>
          <div
            className="cursor-pointer flex items-end gap-x-1 bg-red-400-xxx"
            onClick={() => setAccountExpanded(!accountExpanded)}
          >
            <div className="flex flex-col justify-center z-10">
              <span className="text-xs font-extralight">
                Hello, {isLoggedIn ? "user" : "sign in"}
              </span>
              <span className="text-sm font-bold">Account & Lists</span>
              {accountExpanded && (
                <div
                  className="flex flex-col top-10 absolute outline outline-primary-8 border border-gray-600 rounded bg-primary-7 text-center w-full h-fit p-1.5"
                  onPointerEnter={() => setAccountExpanded(true)}
                >
                  <span
                    className="text-sm text-primary-7 font-semibold py-0.5 px-2 rounded bg-primary-0 active:bg-primary-1"
                    onClick={logout}
                  >
                    Logout!
                  </span>
                </div>
              )}
            </div>
            {/*<img
              className="py-1.5 pointer-events-none z-0"
              src="https://img.icons8.com/ios-filled/10/a7acb2/sort-down.png"
              alt="dropdown arrow"
            />*/}
          </div>
        </div>
        <img
          className="py-1.5 pointer-events-none"
          src="https://img.icons8.com/ios-filled/10/a7acb2/sort-down.png"
          alt="dropdown arrow"
        />
      </div>
      <div className="cursor-pointer flex max-md:hidden items-end gap-1.5">
        <div className="flex flex-col justify-center">
          <span className="text-xs font-extralight">Returns</span>
          <span className="text-sm font-bold">& Orders</span>
        </div>
        <img
          className="py-1.5"
          src="https://img.icons8.com/ios-filled/10/a7acb2/sort-down.png"
          alt="dropdown arrow"
        />
      </div>
      <Link className="cursor-pointer flex items-end gap-1.5" to={"/cart/"}>
        <div className="relative flex flex-col items-center cart-icon-box py-1.5 w-6">
          <span>{totalItems}</span>
          <div className="cart-icon"></div>
        </div>
        <span className="text-sm font-bold">Cart</span>
      </Link>
      <Analytics />
    </div>
  );
};

export default MainNavbar;
