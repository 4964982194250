export default function StarRating({ rating, height = 4 }) {
  return (
    <div className={`flex h-${height}`}>
      {Array.from([1, 2, 3, 4, 5], (index) => (
        <img
          key={index}
          src={`https://m.media-amazon.com/images/G/31/perc/star-${
            index - 0.025 <= rating ? "fullfill" : "empty"
          }.svg`}
          alt="star"
        />
      ))}
    </div>
  );
}
