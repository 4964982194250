import { create } from "zustand";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://lnxmajsxqikqwkpjmfgk.supabase.co";
const supabaseKey =
  process.env.SUPABASE_KEY ||
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxueG1hanN4cWlrcXdrcGptZmdrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTA2OTQzNzcsImV4cCI6MjAyNjI3MDM3N30.eJ1tnKT97kTR6YIncoH93Hj4ZB9SBYftAA5YKm1P2mQ";
export const supabase = createClient(supabaseUrl, supabaseKey);

async function getuser() {
  const {
    data: { user },
  } = await supabase.auth.getUser();
  console.log(user);
  return user ? [user] : [null];
}

export const useAuth = create((set) => ({
  isLoggedIn: localStorage?.getItem("user") ? true : false,
  user: getuser()[0] || null,
  login: async (user) => {
    localStorage?.setItem("user", user);
    const {
      data: { Suser },
    } = await supabase.auth.getUser();
    if (Suser) return set({ isLoggedIn: true, user: Suser });
    return set({ isLoggedIn: true, user: user });
  },
  logout: async () => {
    localStorage?.removeItem("user");
    localStorage?.removeItem("userCart");
    await supabase.auth.signOut();
    return set({ isLoggedIn: false, user: null });
  },
}));

//// this hook is removed after Shlok Sir's suggestion on stand-up [8/4/24]
// export const useSearchData = create((set) => ({
//   searchData: localStorage.getItem("userSearchData") || "",
//   setSearchData: (searchValue) => set({ searchData: searchValue }),
// }));

//// this hook is removed after Shlok Sir's suggestion on stand-up [8/4/24]
// export const useProduct = create((set) => ({
//   productId: localStorage.getItem("currentImage") || "",
//   setProductId: (id) =>
//     set(() => {
//       localStorage.setItem("currentImage", id);
//       return { productId: id };
//     }),
// }));

/*
// This function's working is Shifted within the hook itself
async function getUserCart() {
  const {
    data: { user },
  } = await supabase.auth.getUser();
  if (!user) return { cart_item: [], total_items: 0 };
  let { data: user_cart } = await supabase
    .from("user_cart")
    .select("*")
    .eq("user_id", user?.id);
  console.log(user_cart[0]);
  localStorage.setItem(
    "userCart",
    JSON.stringify({
      data: useCart[0].cart_items,
      items: useCart[0].total_items,
    })
  );
  return user_cart[0];
}
*/

export const useCart = create((set) => ({
  cart: JSON.parse(localStorage?.getItem("userCart"))?.data || [],
  totalItems: JSON.parse(localStorage?.getItem("userCart"))?.items || 0,
  updateCart: async (newCart, items) => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) return;
    let { data: user_cart } = await supabase
      .from("user_cart")
      .select("user_id")
      .eq("user_id", user.id);
    if (user_cart.length) {
      await supabase
        .from("user_cart")
        .update({ cart_items: newCart, total_items: items })
        .eq("user_id", user.id)
        .select();
    } else {
      await supabase
        .from("user_cart")
        .insert([{ user_id: user.id, cart_items: newCart, total_items: items }])
        .select();
    }
  },
  updateLocalCart: async () => {
    //set(async () => {
    let userCart = [],
      totalItems = 0;
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user) {
      const { data: user_cart } = await supabase
        .from("user_cart")
        .select("*")
        .eq("user_id", user?.id);
      if(user_cart.length === 0) return;
      userCart = user_cart[0].cart_items;
      totalItems = user_cart[0].total_items;
    }
    console.log(
      "Your Saved Cart is : ",
      userCart,
      "\n With total items : ",
      totalItems
    );
    localStorage?.setItem(
      "userCart",
      JSON.stringify({
        data: userCart,
        items: totalItems,
      })
    );
    return set({
      cart: userCart,
      items: totalItems,
    });
  },
  addToCart: ({ product, quantity }) => 
    set((state) => {
      console.log("product to add ->", product, " quantity to add->", quantity);
      let newCart = [];
      const items = state.totalItems + quantity * 1.0;
      if (state.cart.find((p) => p.product["Uniq Id"] === product["Uniq Id"])) {
        newCart = state.cart.map((pro) =>
          pro.product["Uniq Id"] === product["Uniq Id"]
            ? {
                product: product,
                quantity: (pro.quantity * 1.0) + (quantity * 1.0),
              }
            : pro
        );
      } else {
        newCart = [
          ...state.cart,
          { product: product, quantity: quantity * 1.0 },
        ];
      }
      localStorage?.setItem(
        "userCart",
        JSON.stringify({ data: newCart, items: items })
      );
      state.updateCart(newCart, items);
      return {
        cart: newCart,
        totalItems: items,
      };
    }),
  removeitemsFromCart: (product) =>
    set((state) => {
      const newCart = state.cart.filter(
        (pro) => pro?.product["Uniq Id"] !== product?.product["Uniq Id"]
      );
      const items = state.totalItems - product?.quantity * 1.0;
      localStorage?.setItem(
        "userCart",
        JSON.stringify({ data: newCart, items: items })
      );
      state.updateCart(newCart, items);
      return {
        cart: newCart,
        totalItems: items,
      };
    }),
  removeNitemsFromCart: ({ product, quantity }) =>
    set((state) => {
      let newCart = [];
      const items = state.totalItems - quantity * 1.0;
      let findItem = state.cart?.find(
        (p) => p?.product["Uniq Id"] === product["Uniq Id"]
      );
      if (findItem?.quantity <= quantity) {
        newCart = state.cart.filter(
          (p) => p?.product["Uniq Id"] !== product["Uniq Id"]
        );
      } else {
        newCart = state.cart?.map((pro) =>
          pro.product["Uniq Id"] === product["Uniq Id"]
            ? {
                product: pro?.product,
                quantity: pro?.quantity - quantity * 1.0,
              }
            : pro
        );
      }
      localStorage?.setItem(
        "userCart",
        JSON.stringify({ data: newCart, items: items })
      );
      state.updateCart(newCart, items);
      return {
        cart: newCart,
        totalItems: items,
      };
    }),
  resetCart: () =>
    set((state) => {
      localStorage?.setItem("userCart", null);
      state.updateCart([], 0);
      return { cart: [], totalItems: 0 };
    }),
}));

// https://m.media-amazon.com/images/G/31/personalization/ybh/loading-4x-gray._CB485916689_.gif
// https://m.media-amazon.com/images/S/sash/JT89MwO$JunoYts.png

/*
updateLocalCart: () => {
    const getUserCart = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        const { data: user_cart } = await supabase
          .from("user_cart")
          .select("*")
          .eq("user_id", user?.id);
        if (user_cart)
          return {
            userCart: user_cart[0].cart_items,
            totalItems: user_cart[0].total_items,
          };
        else return { userCart: [], totalItems: 0 };
      } else return { userCart: [], totalItems: 0 };
    };
    const userCloudCart = getUserCart();
    const { userCart, totalItems } = userCloudCart;
    console.log(
      "Your Saved Cart is : ",
      userCart,
      "\n With total items : ",
      totalItems
    );
    localStorage.setItem(
      "userCart",
      JSON.stringify({
        data: userCart,
        items: totalItems,
      })
    );
    return set({
      cart: userCart,
      items: totalItems,
    });
  },
  */
