import { MainFooterData } from "../DataStore/GlobalData";

const MainFooter = () => {
  return (
    <div className="flex flex-col text-xs bg-primary-9 text-white py-2">
      <div className="grid grid-cols-4 font-medium xl:px-64 lg:px-32 gap-4 px-16 py-6">
        {MainFooterData.map((column, i) => (
          <div key={"main-footer-column" + i} className="flex flex-col">
            {column?.map((data, index) => (
              <span
                key={"main-footer-data-" + data + index}
                className={`text-gray-300 ${index === 0 && "font-semibold"}`}
              >
                {data}
              </span>
            ))}
          </div>
        ))}
      </div>
      <div className="flex flex-col font-semibold items-center text-primary-4 opacity-80 p-4">
        <div className="flex gap-4">
          <span>Conditions of Use & Sale</span>
          <span>Privacy Notice</span>
          <span>Interest-Based Ads</span>
        </div>
        <span>© 2024, psiacestore.eshank.biz, Inc. or its affiliates</span>
      </div>
    </div>
  );
};
export default MainFooter;
