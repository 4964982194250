import { HomeCategories } from "../DataStore/GlobalData";

const CategoryNavbar = () => {
  return (
    <div className="category-navbar flex items-center text-nowrap bg-primary-7 border-y border-primary-7 w-full px-4">
      <button className="flex items-center text-white border border-transparent hover:border-white rounded gap-2 mx-2 px-1 py-1.5">
        <img
          src="https://img.icons8.com/ios-filled/18/ffffff/menu.png"
          alt="menu nav icon"
        />
        <span className="text-sm font-semibold">All</span>
      </button>
      {HomeCategories.map((category, index) => (
        <button
          key={"category-" + category + index}
          // key={JSON.stringify(category) + "-category" + index}
          className="py-2 px-2 text-sm font-normal text-white border border-transparent hover:border-white rounded-sm"
        >
          {category}
        </button>
      ))}
    </div>
  );
};
export default CategoryNavbar;
