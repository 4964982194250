import { Link } from "react-router-dom";
import {
  SiteFooterData,
  SiteFooterLanguagesData,
} from "../DataStore/GlobalData";

const SiteFooter = () => {
  return (
    <div className="bg-slate-800-xxx bg-primary-10 text-white py-2">
      <div className="flex justify-evenly font-medium xl:px-14 px-6 py-10">
        {SiteFooterData.map((column, i) => (
          <div
            key={"site-footer-upper-column-" + i}
            className="flex flex-col gap-2"
          >
            <h4>{column.header}</h4>
            {column.links.map((link) => (
              <Link
                key={"link-to-" + link.title}
                className="text-sm text-gray-300"
                to={link.href}
              >
                {link.title}
              </Link>
            ))}
          </div>
        ))}
      </div>
      <hr className="opacity-20"></hr>
      <div className="flex flex-col gap-6 xl:px-14 px-6 py-10">
        <div className="flex justify-evenly px-12 md:px-48 xl:px-96">
          <img
            className="h-8"
            src="https://pngimg.com/uploads/amazon/amazon_PNG11.png"
            alt="Amazon Logo"
          />
          <select
            className="border border-0.5 font-light border-primary-4 rounded-sm bg-[#00000019] px-2 py-1 text-sm"
            defaultValue={"English - EN"}
          >
            {[
              "English - EN",
              "हिन्दी - HI",
              "தமிழ் - TA",
              "తెలుగు - TE",
              "ಕನ್ನಡ - KN",
              "മലയാളം - ML",
              "বাংলা - BN",
              "मराठी - MR",
            ].map((lang) => (
              <option
                key={"language-selection-for-" + lang}
                className="text-black"
              >
                {lang}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center text-xs gap-4 flex-wrap leading-none xl:px-48 px-16">
          {SiteFooterLanguagesData.map((country) => (
            <Link key={"link-to-language-of-" + country + "-country"} to={"/"}>
              {country}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SiteFooter;
