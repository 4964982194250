const ProductsFlow = ({ products }) => {
  return (
    <div className="products-flow flex flex-col bg-white gap-2.5 p-5 my-5">
      <div className="flex flex-1 items-end gap-4">
        <span className="text-xl font-semibold overflow-x-hidden">
          {products?.title}
        </span>
        <span className="cursor-pointer text-sm text-primary-6 hover:text-red-600 hover:underline">
          See all deals
        </span>
      </div>
      <div className="text-xs flex overflow-x-scroll overflow-y-hidden gap-2">
        {products?.data?.map((product, i) => (
          <span
            key={"product-img-for-" + products.title + "-item-" + (i + 1)}
            className="min-w-product flex justify-center"
          >
            <img src={product.img} alt="product img" />
          </span>
        ))}
      </div>
    </div>
  );
};
export default ProductsFlow;
