import { useState } from "react";

const OptionMenu = ({ menu }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div className="flex flex-col text-sm w-64">
      <span className="font-semibold py-0.5">{menu?.title}</span>
      {menu?.options?.map((item, index) => {
        return !collapsed || menu?.options?.length < 10 || index < 7 ? (
          <div key={"option-item-"+item+"-"+index} className="flex items-center gap-1">
            <input
              className="cursor-pointer scale-125"
              id={"brand" + item}
              type="checkbox"
            />
            <label className="cursor-pointer" htmlFor={"brand" + item}>
              {item}
            </label>
          </div>
        ) : (
          ""
        );
      })}
      {menu?.options?.length >= 10 && (
        <div
          className="cursor-pointer flex items-center gap-1"
          onClick={() => setCollapsed(!collapsed)}
        >
          <img
            src={`https://img.icons8.com/ios/11/000000/${
              collapsed ? "expand" : "collapse"
            }-arrow.png`}
            alt="expand/collapse arrow"
          />
          <span className="text-primary-6">
            See {collapsed ? "more" : "less"}
          </span>
        </div>
      )}
    </div>
  );
};
export default OptionMenu;
