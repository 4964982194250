import { useEffect, useState } from "react";
import { CarouselData } from "../DataStore/HomeData";

const Carousel = () => {
  const [currentCarousel, setCurrentCarousel] = useState(0);
  const totalCarousel = CarouselData.length;

  const previousCarousel = () => {
    currentCarousel === 0
      ? setCurrentCarousel(totalCarousel - 1)
      : setCurrentCarousel(currentCarousel - 1);
    // console.log(currentCarousel);
  };

  const nextCarousel = () => {
    currentCarousel === totalCarousel - 1
      ? setCurrentCarousel(0)
      : setCurrentCarousel(currentCarousel + 1);
    // console.log(currentCarousel);
  };

  useEffect(() => {
    const interval = setInterval(() => nextCarousel(), 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [currentCarousel]);

  return (
    <div className="absolute top-0 home-carousel">
      <div className="cursor-pointer absolute top-0 carousel-move flex w-full z-50">
        <div
          className="carousel-previous relative cursor-pointer py-24 px-4"
          onClick={previousCarousel}
        >
          <img
            className="absolute right-3.5 rotate-90"
            src="https://img.icons8.com/ios/42/ffffff/expand-arrow--v2.png"
            alt="previous item"
          />
          <img
            className="rotate-90"
            src="https://img.icons8.com/ios/42/000000/expand-arrow--v2.png"
            alt="previous item"
          />
        </div>
        <div className="carousel-seperator flex-1"></div>
        <div
          className="carousel-next relative cursor-pointer py-24 px-4"
          onClick={nextCarousel}
        >
          <img
            className="absolute left-3.5 rotate-90"
            src="https://img.icons8.com/ios/42/ffffff/collapse-arrow--v2.png"
            alt="next item"
          />
          <img
            className="rotate-90"
            src="https://img.icons8.com/ios/42/000000/collapse-arrow--v2.png"
            alt="next item"
          />
        </div>
      </div>
      <div className="relative carousel-card flex overflow-x-hidden z-10">
        <img src={CarouselData[currentCarousel]} alt="carousel card" />
      </div>
    </div>
  );
};

export default Carousel;
