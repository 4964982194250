import { Link } from "react-router-dom";

const Poster = ({ data: poster }) => {
  return (
    <div className="poster-card flex flex-col items-center bg-white gap-1 min-w-80 px-3 pt-5 pb-3.5">
      <div className="flex flex-col flex-1 px-1">
        <span className="text-xl font-semibold px-2 w-80">
          {poster?.heading}
        </span>
        {poster.type === "single" ? (
          <img
            className="h-[345px] object-cover p-2.5"
            src={poster.img}
            alt={poster.heading}
          />
        ) : (
          <div className="grid grid-cols-2 text-xs font-light leading-snug w-fit gap-4 p-2.5">
            {poster?.data.map((figure, index) => {
              return poster.type === "full" ? (
                <div
                  key={"poster-image-box-for-" + figure.title + index}
                  className="poster-figure flex flex-col gap-1"
                >
                  <img
                    className="w-36 h-24"
                    src={figure.img}
                    alt={figure.title}
                  />
                  <span>{figure.title}</span>
                </div>
              ) : poster.type === "untitled" ? (
                <img
                  key={"poster-image-box-for-" + poster.heading + index}
                  className="w-36 max-h-[155px] h-fit object-contain"
                  src={figure.img}
                  alt={poster.heading}
                />
              ) : (
                <></>
              );
            })}
          </div>
        )}
      </div>
      {poster?.hyperLinkTitle && (
        <Link
          to={"/"}
          className="cursor-pointer text-sm text-primary-6 hover:text-red-600 hover:underline w-80 p-2"
        >
          {poster?.hyperLinkTitle}
        </Link>
      )}
    </div>
  );
};
export default Poster;
