// import { useState } from "react";
import StarRating from "../Components/StarRating";
import { getProduct } from "../DataStore/ProductsData";
import { useCart } from "../store";

const CartProduct = ({ product: pro }) => {
  const { removeitemsFromCart } = useCart();
  const product = getProduct(pro.product["Uniq Id"]);
  return (
    <div className="flex items-start gap-3 ps-4 py-5">
      {/* <input type="checkbox" className="cursor-pointer scale-125" /> */}

      <div className="flex border rounded-lg min-w-44 w-44 max-w-44 min-h-44 h-44 max-h-44 gap-0.5 p-1.5">
        <img
          className="object-cover rounded w-full h-full flex-1"
          src={product["Image"][0]}
          alt="product banner"
        />
      </div>

      <div className="flex flex-col flex-1 h-full ps-1">
        <div className="flex flex-col flex-1">
          <span className="text-xl font-medium">
            {product["Product Name"]}
          </span>
          <span className="text-xs text-primary-6">In stock</span>
          <span className="text-xs">Eligible for FREE Shipping</span>
          <div className="text-sm stars flex items-center gap-1">
            <span>
              {product
                ? product["Rating"] * 1.0 -
                  ((product["Rating"] * 1.0) % 5.0)
                : ""}
            </span>
            <StarRating rating={product["Rating"]} height={4} />
            <img
              width={8}
              src={`https://img.icons8.com/ios/8/000000/expand-arrow.png`}
              alt="expand arrow"
            />
          </div>
          <span className="text-xs font-semibold">
            <span className="text-green-600">
              {Math.ceil(product["Stock"] / 10) * 10}+
            </span>{" "}
            bought in past month
          </span>
        </div>

        <div className="flex flex-1 flex-wrap items-center gap-2.5 divide-x divide-primary-0">
          <span
            className="cursor-pointer text-primary-6 ps-2.5"
            onClick={() => removeitemsFromCart(pro)}
          >
            Remove from cart
          </span>
          <span className="cursor-pointer text-primary-6 ps-2.5">
            Save for later{" "}
          </span>
          <span className="cursor-pointer text-primary-6 ps-2.5">
            See more like this
          </span>
          <span className="cursor-pointer text-primary-6 ps-2.5">Share</span>
        </div>
      </div>
      <div className="flex flex-col items-end h-full">
        <div className="flex text-sm items-end gap-1.5 py-2">
          <span className="text-sm font-thin text-white bg-primary-3 leading-none p-1">
            {Math.ceil(product["Discount"])}% off
          </span>
          <span className="font-thin text-primary-3">Limited time deal</span>
        </div>
        <div className="flex items-end">
          <span className="text-sm self-start leading-none">₹</span>
          <span className="text-xl font-semibold leading-none">
            {product["New Price"]}
          </span>
        </div>
        <div className="flex text-xs font-medium items-end gap-1.5 py-2">
          <span className="opacity-80 leading-none">
            M.R.P: <s>₹{product["Old Price"]}</s>
          </span>
        </div>
        <span className="text-sm border rounded-lg px-2">
          quantity: <span className="font-semibold">{pro?.quantity}</span>
        </span>
      </div>
    </div>
  );
};
export default CartProduct;
