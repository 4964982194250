import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import SearchPage from "./Search";
import Page404 from "./Page404";
import ProductPage from "./Product";
import CartPage from "./Cart";
import Authenticate from "./Auth";
import { supabase, useAuth } from "./store";

const App = () => {
  const { isLoggedIn } = useAuth();
  const isSupabaseLoggedIn = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    return user;
  };
  const router = createBrowserRouter(
    isLoggedIn && isSupabaseLoggedIn()
      ? [
          { path: "/", errorElement: <Page404 />, element: <Home /> },
          { path: "/auth/*", element: <Authenticate /> },
          { path: "/search/:searchData", element: <SearchPage /> },
          { path: "/product/:productId", element: <ProductPage /> },
          { path: "/cart/", element: <CartPage /> },
        ]
      : [{ path: "/*", errorElement: <Page404 />, element: <Authenticate /> }]
  );
  return <RouterProvider router={router} />;
};

export default App;
