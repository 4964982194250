import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth, supabase, useCart } from "../store";
import { Analytics } from "@vercel/analytics/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Authenticate = () => {
  const { login } = useAuth();
  const [disclamerExpanded, setDisclamerExpanded] = useState(true);
  const [isSignin, setSignin] = useState(true);
  const [awaitConfirmation, setSignUpStatus] = useState(null);
  // const [ pageType, setPageType ] = useState("signin");
  const { updateLocalCart } = useCart();

  const [firstView, seFirstView] = useState(false);
  useEffect(() => seFirstView(true), []);

  // const [user, setUser] = useState(null);
  const [loginError, setError] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordView, setPasswordView] = useState(false);

  async function signInUser() {
    let { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    console.log("data -> ", data, "\nerror -> ", error);
    if (error) setError(error.message);
    else if (!error && data) {
      const {
        data: { suser },
      } = await supabase.auth.getUser();
      // setUser(suser);
      await updateLocalCart();
      login(suser);
      setError(null);
      window.location.reload();
    }
  }

  async function signUpUser() {
    let { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
    });
    console.log("data -> ", data, "\nerror -> ", error);
    if (error) {
      setError(error.message);
      return error;
    } else {
      setError(null);
      return null;
    }
  }

  const SignUpUserAndNotify = () =>
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const res = await signUpUser();
          if (res) reject(res);
          else {
            setSignUpStatus(true);
            await supabase.auth.signOut();
            resolve("Confirmation Email Sent!");
          }
        } catch (error) {
          reject(error);
        }
      }),
      {
        pending: "Sending you a confirmation Email...",
        success: "Confirmation Email Sent Successfully!",
        error:
          "Error Occured while sending you a confirmation email! Please try again later.",
      }
    );

  function handleSubmit(e) {
    e.preventDefault();
    isSignin ? signInUser() : SignUpUserAndNotify();
  }

  return (
    <div className="relative flex flex-col gap-4 w-full max-w-screen overflow-x-hidden h-full h-screen">
      <div className="flex flex-col items-center gap-4 p-4">
        <Link
          className="cursor-pointer flex amazon-logo items-start min-w-24"
          to={"/"}
        >
          <img
            height={38}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/603px-Amazon_logo.svg.png?20220213013322"
            alt="PsiaceStore Logo"
          />
          <span>.in</span>
        </Link>
        {awaitConfirmation && !isSignin ? (
          <div className="flex flex-col border rounded divide-y w-min gap-4 p-5">
            <div className="flex flex-col w-min gap-2.5">
              <span className="text-3xl mb-1.5">
                Confirm your email address
              </span>
              <span className="flex flex-col gap-2.5 text-xs text-wrap w-72">
                <span>
                  → A Confirmation Email has been sent to your provided Email
                  Address.
                </span>
                <span>
                  → Please Go to your Email Inbox and confirm your Email Address
                  to continue further.
                </span>
              </span>
            </div>
          </div>
        ) : (
          <div className="flex flex-col border rounded divide-y w-min gap-4 p-5">
            <div className="flex flex-col w-min gap-2.5">
              <span className="text-3xl mb-1.5">
                Sign {isSignin ? "in" : "up"}
              </span>
              <form
                className="flex flex-col gap-1.5"
                onSubmit={(e) => handleSubmit(e)}
              >
                <span className="text-xs font-semibold">
                  Email or mobile phone number
                </span>
                <input
                  className="text-sm rounded-sm border active:border-[#017184] border-black outline outline-4 active:outline-[#c8f3fa] hover:outline-[#c8f3fa] outline-white rounded w-72 py-1 px-2.5"
                  type="email"
                  value={email}
                  placeholder="Enter your Email Address!"
                  onInput={(e) => setEmail(e.target.value)}
                />
                {email && (
                  <div className="relative flex justify-end items-center w-72">
                    <input
                      className="password text-sm rounded-sm border active:border-[#017184] border-black outline outline-4 active:outline-[#c8f3fa] hover:outline-[#c8f3fa] outline-white rounded flex-1 py-1 ps-2.5 pe-8"
                      type={passwordView ? "text" : "password"}
                      value={password}
                      placeholder="Enter your password!"
                      onInput={(e) => setPassword(e.target.value)}
                    />
                    <img
                      className="absolute cursor-pointer px-1"
                      onClick={() => setPasswordView(!passwordView)}
                      src={`https://img.icons8.com/fluency-systems-filled/18/${
                        passwordView ? "visible" : "hide"
                      }.png`}
                      alt="show/hide password"
                    />
                  </div>
                )}
                <button
                  type="submit"
                  className="text-xs font-medium bg-[#ffd812] active:bg-primary-0 rounded-lg shadow p-1.5 my-2.5"
                >
                  Continue
                </button>
              </form>
              {loginError && (
                <div className="text-sm text-center text-red-600 font-medium">
                  {loginError}
                </div>
              )}
              {!isSignin && (
                <span className="text-xs text-wrap">
                  By continuing, you agree to PsiaceStore's{" "}
                  <span className="text-blue-700">Conditions of Use</span> and{" "}
                  <span className="text-blue-700">Privacy Notice.</span>
                </span>
              )}
              <div className="flex flex-col py-2.5">
                <span className="text-xs text-blue-700"> → Need help?</span>
              </div>
            </div>

            <div className="flex flex-col gap-1.5 pt-4">
              <span className="text-xs font-semibold">Buying for work?</span>
              <span className="text-xs text-blue-700 font-medium">
                Shop on PsiaceStore Business
              </span>
            </div>
          </div>
        )}
        <div className="flex items-center text-xs text-gray-500 gap-2 w-80">
          <hr className="flex-1" />
          <span>
            {isSignin ? "New to PsiaceStore?" : "Already have an Account?"}
          </span>
          <hr className="flex-1" />
        </div>
        <button
          className={`text-xs font-medium border shadow scale-105 rounded-lg w-80 active:bg-gray-100 py-1.5 px-2`}
          onClick={() => setSignin(!isSignin)}
        >
          {isSignin
            ? "Create your PsiaceStore account"
            : "Login to your PsiaceStore account"}
        </button>
      </div>
      <div className="bg-[#fcfcfc] flex flex-col items-center text-xs flex-1 gap-3 h-max">
        <div className="auth-footer-seperation h-0.5 w-full"></div>
        <div className="text-blue-700 flex scale-90 gap-7 pt-4">
          <span>Conditions of Use</span> <span>Privacy Notice</span>{" "}
          <span>Help</span>
        </div>
        <span className="text-gray-800 scale-90">
          © 1996-2024, PsiaceStore.Eshank.Biz, Inc. or its affiliates
        </span>
      </div>
      <div
        className={`absolute ${
          firstView
            ? "flex justify-center items-center bg-[#00000080] h-full w-full p-16"
            : "bottom-8 right-8 min-w-44 max-w-60 lg:max-w-80 min-h-12"
        }`}
      >
        {disclamerExpanded && (
          <div
            className={`flex flex-col items-center text-center gap-2 rounded border-2 max-md:scale-75 ${
              firstView
                ? "bg-white h-fit max-w-fit min-h-1/2 md:w-1/2 justify-center text-lg border-4 md:p-10 max-md:p-2.5"
                : "text-xs border-red-500 opacity-65 w-full md:p-5"
            }`}
          >
            <div
              className={`relative flex justify-center items-center text-red-500 font-bold w-full`}
            >
              <span className={firstView ? "text-2xl py-6" : "text-lg py-2"}>
                ⚠️ DISCLAMER ⚠️
              </span>
              {firstView && ( 
                <button 
                  className="absolute top-0 right-0 rounded-full hover:shadow w-fit h-fit my-3.5 p-1.5 z-10"
                  onClick={() => seFirstView(!firstView)}
                >
                  <img
                    src={`https://img.icons8.com/ios/38/000000/${
                      disclamerExpanded ? "multiply" : "minus"
                    }.png`}
                    alt="close"
                  />
                </button>
              )}
            </div>
            <span className="text-red-800 italic">
              {`This page and this website are made for educational purposes only!
              and as part of learning a tech-stack through a live project, and
              hence this website is not affiliated with Amazon or Amazon.in in
              any way except it's UI is the same.`}
            </span>
            <span className="text-red-800 italic">
              {`Please DO NOT enter your actual Amazon account details here! ${
                firstView &&
                `And be aware that clone websites are not neccessarily secure and your data can be prone to get stolen or viewed by the developers of this clone website. Be responsible for your own data!`
              }`}
            </span>
          </div>
        )}
        {!firstView && (
          <button
            className="absolute top-2 right-2 rounded-full hover:shadow p-1.5"
            onClick={() => setDisclamerExpanded(!disclamerExpanded)}
          >
            <img
              src={`https://img.icons8.com/ios/24/000000/${
                disclamerExpanded ? "multiply" : "minus"
              }.png`}
              alt="close"
            />
          </button>
        )}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        rtl={false}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Analytics />
    </div>
  );
};
export default Authenticate;

/*
data?.user?.aud === "authenticated" || data?.user?.role === "authenticated"
 */
