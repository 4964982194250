// ----------------------------------------------------------------- //

//

// ---------------- --- -- - Home Page Data - -- --- --------------- //

//

// -------------------------- Carousel Data -------------------------- //

export const CarouselData = [
  "https://images-eu.ssl-images-amazon.com/images/G/31/img21/OHL/Deals/GW/unrec/March/DEALS_HERO-2X._CB579826609_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/img23/Beauty/GW/revised/Makeup-PC_3000_1200._CB561093960_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/Biss_2023/BISS_GW/GWNEW/GWNEW2/GW_PC_1500x600._CB572878474_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/img24/Consumables/GW/Mar18/QC/PC_GW_Hero_3000x1200_01._CB579486410_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/img22/march/brands/GW/Under_1499_Tallhero_3000x1200._CB561212093_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Wireless/devjyoti/GW/Uber/Nov/D103625178_DesktopTallHero_3000x1200._CB574597993_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/img2020/img21/apparelGW/augatf23/hsbcunrec/MA_2x._CB580708889_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/BISS_2024/GW_BAU/PC-Hero._CB583028618_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/IMG20/Home/2024/Gateway/ATFGW/GW_Storage-box_3000x1200_PC._CB561623684_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/IMG24/Smart_Watches/1500X600_Premium-3rdpr._CB561931599_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Baby/cnnjpp1/Baby/PC_Hero_3000x1200_04_min50._CB561910839_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/prime/ACQ/Hero_1500x6001._CB592142345_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Unrec/TallHero_1500X600_Unrec._CB593464763_.jpg",
  "https://images-eu.ssl-images-amazon.com/images/G/31/IMG24/Smart_Watches/3000X1200_New_Launch_1stapr._CB561301598_.jpg",
  "https://m.media-amazon.com/images/I/61aURrton0L._SX3000_.jpg",
];

// ------------------------ Full Posters Data ----------------------- //

export const FullPostersData = [
  {
    type: "full",
    heading: "Revamp your home in style",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG20/Home/2024/Gateway/BTFGW/PCQC/186x116_Home_furnishings_2._SY116_CB584596691_.jpg",
        title: "Cushion covers, bedsheets & more",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG20/Home/2024/Gateway/BTFGW/PCQC/186x116_Home_decor_1._SY116_CB584596691_.jpg",
        title: "Figurines, vases and more",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG20/Home/2024/Gateway/BTFGW/PCQC/186x116_Home_storage_1._SY116_CB584596691_.jpg",
        title: "Home storage",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG20/Home/2024/Gateway/BTFGW/PCQC/186x116_Home_lighting_2._SY116_CB584596691_.jpg",
        title: "Lighting solutions",
      },
    ],
    hyperLinkTitle: "Explore all",
  },
  {
    type: "full",
    heading: "Up to 60% off | Styles for men",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PF_MF/MF-1-186-116._SY116_CB636110853_.jpg",
        title: "Clothing",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PF_MF/MF-2-186-116._SY116_CB636110853_.jpg",
        title: "Footwear",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PF_MF/MF-3-186-116._SY116_CB636110853_.jpg",
        title: "Watches",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PF_MF/MF-4-186-116._SY116_CB636110853_.jpg",
        title: "Bags & wallets",
      },
    ],
    hyperLinkTitle: "End of season sale",
  },
  {
    type: "full",
    heading: "Appliances for your home | Up to 55% off",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG15/Irfan/GATEWAY/MSO/Appliances-QC-PC-186x116--B08RDL6H79._SY116_CB667322346_.jpg",
        title: "Air conditioners",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG15/Irfan/GATEWAY/MSO/Appliances-QC-PC-186x116--B08345R1ZW._SY116_CB667322346_.jpg",
        title: "Refrigerators",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG15/Irfan/GATEWAY/MSO/Appliances-QC-PC-186x116--B07G5J5FYP._SY116_CB667322346_.jpg",
        title: "Microwaves",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG15/Irfan/GATEWAY/MSO/186x116---wm._SY116_CB667322346_.jpg",
        title: "Washing machines",
      },
    ],
    hyperLinkTitle: "See more",
  },
  {
    type: "full",
    heading: "Starting ₹99 | All your home improvement needs",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img23/HI/SYED/ATF/QC-adapts_186x116_4._SY116_CB600489960_.jpg",
        title: "Spin mops, wipes & more",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img23/HI/SYED/ATF/QC-adapts_186x116_3._SY116_CB600489960_.jpg",
        title: "Bathroom hardware & accessories",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img23/HI/SYED/ATF/QC-adapts_186x116_7._SY116_CB600489960_.jpg",
        title: "Hammers, screwdrivers & more",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img23/HI/SYED/ATF/QC-adapts_186x116_5._SY116_CB600489960_.jpg",
        title: "Extension boards, plugs & more",
      },
    ],
    hyperLinkTitle: "Explore all",
  },
  {
    type: "full",
    heading: "Up to 75% off | Headphones",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/MSO/CE/Unrec1/Boat_Desktop_Qc_1x._SY116_CB577919562_.jpg",
        title: "Up to 75% off | boAt",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/MSO/CE/Unrec1/Boult_Desktop_Qc_1x._SY116_CB577919562_.jpg",
        title: "Up to 75% off | boult",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/MSO/CE/Unrec1/Noise_Desktop_Qc_1x._SY116_CB577919562_.jpg",
        title: "Up to 75% off | Noise",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/MSO/CE/Unrec1/Zebronics_Desktop_Qc_1x._SY116_CB577919562_.jpg",
        title: "Up to 75% off | Zebronics",
      },
    ],
    hyperLinkTitle: "See all offers",
  },
  {
    type: "full",
    heading: "Discover TVs by your ideal size | Starting ₹6,999",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG23/TVs/Manish/BAU/MARCH/pri/rev/revs/shop/32._SY116_CB578871436_.jpg",
        title: "Budget TVs | Save extra with No Cost EMI",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG23/TVs/Manish/BAU/MARCH/pri/rev/revs/shop/43._SY116_CB578871436_.jpg",
        title: "4K TVs | Up to 24 months No Cost EMI",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG23/TVs/Manish/BAU/MARCH/pri/rev/revs/shop/55._SY116_CB578871436_.jpg",
        title: "Big Screens | Free installation",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/IMG23/TVs/Manish/BAU/MARCH/pri/rev/revs/shop/65._SY116_CB578871436_.jpg",
        title: "Ultra Premium TVs | Up to 50% off",
      },
    ],
    hyperLinkTitle: "See all",
  },
  {
    type: "full",
    heading: "Up to 60% off | Styles for women",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PC_WF/WF1-186-116._SY116_CB636048992_.jpg",
        title: "Women's Clothing",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PC_WF/WF2-186-116._SY116_CB636048992_.jpg",
        title: "Footwear+Handbags",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PC_WF/WF4-186-116._SY116_CB636048992_.jpg",
        title: "Watches",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PC_WF/WF3-186-116._SY116_CB636048992_.jpg",
        title: "Fashion jewellery",
      },
    ],
    hyperLinkTitle: "End of season sale",
  },
  {
    type: "full",
    heading: "Baby essentials & games | PsiaceStore Brands & more",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/AmazonBrands/GW_CPB_/QC_CC/QC_PC_186x116_1._SY116_CB581980791_.jpg",
        title: "Minimum 50% off | Diapers & wipes",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/AmazonBrands/Bikram/Bikram1/PC_QC_186x116_5_CPB01._SY116_CB570992602_.jpg",
        title: "Minimum 40% off | Baby cardels & more",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/AmazonBrands/GW_CPB_/QC_CC/QC_PC_186x116_6._SY116_CB585042283_.jpg",
        title: "Minimum 40% off | Soft toys & Indoor games",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/AmazonBrands/GW_CPB_/QC_CC/QC_PC_186x116_7._SY116_CB585040546_.jpg",
        title: "Minimum 40% off | Outdoor games",
      },
    ],
    hyperLinkTitle: "See more",
  },
  {
    type: "full",
    heading: "Minimum 50% off | Top styles for him",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img2020/img21/kids/WRS_Dec22/PC_QC/MA/1_1X._SY116_CB618756929_.jpg",
        title: "Sweatshirts & hoodies",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img2020/img21/kids/WRS_Dec22/PC_QC/MA/2_1X._SY116_CB618756929_.jpg",
        title: "Jackets",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img2020/img21/kids/WRS_Dec22/PC_QC/MA/3_1X._SY116_CB618756929_.jpg",
        title: "T-shirts, Polos & more",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img2020/img21/kids/WRS_Dec22/PC_QC/MA/4_1X._SY116_CB618756929_.jpg",
        title: "Jeans",
      },
    ],
    hyperLinkTitle: "See all deals",
  },
  {
    type: "full",
    heading: "Minimum 50% off | Top styles for her",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img2020/img21/kids/WRS_Dec22/PC_QC/WA/1_1X._SY116_CB618751254_.jpg",
        title: "Allen Solly, Fort Collins & more",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img2020/img21/kids/WRS_Dec22/PC_QC/WA/2_1X._SY116_CB618751254_.jpg",
        title: "Biba, Aurelia & more",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img2020/img21/kids/WRS_Dec22/PC_QC/WA/3_1X._SY116_CB618751254_.jpg",
        title: "Womanista, Mimosa & more",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img2020/img21/kids/WRS_Dec22/PC_QC/WA/4_1X._SY116_CB618751254_.jpg",
        title: "Harpa, Only & more",
      },
    ],
    hyperLinkTitle: "See all deals",
  },
  {
    type: "full",
    heading: "Under ₹499 | Pocket-friendly fashion",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PC-PFF/PFF-1-186-116._SY116_CB636055991_.jpg",
        title: "Clothing",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PC-PFF/PFF-3-186-116._SY116_CB636055991_.jpg",
        title: "Backpacks",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PC-PFF/PFF-2-186-116._SY116_CB636055991_.jpg",
        title: "Footwear",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/img22/Fashion/Gateway/BAU/BTF-Refresh/May/PC-PFF/PFF-4-186-116._SY116_CB636055991_.jpg",
        title: "View all",
      },
    ],
    hyperLinkTitle: "See all offers",
  },
  {
    type: "full",
    heading: "Up to 70% off | Explore more from bestsellers",
    data: [
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/Local/Wk12/LA_1x._SY116_CB579146779_.jpg",
        title: "Appliances",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/Local/Wk12/Furn_1x._SY116_CB579146779_.jpg",
        title: "Furniture",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/Local/Wk12/HD_1x._SY116_CB579146779_.jpg",
        title: "Home decor",
      },
      {
        img: "https://images-eu.ssl-images-amazon.com/images/G/31/Local/Wk12/KA_1x._SY116_CB579146779_.jpg",
        title: "Kitchen appliances",
      },
    ],
    hyperLinkTitle: "See more",
  },
];

export const UntitledPostersData = [
  {
    type: "untitled",
    heading: "Bestsellers in Women's Indian Clothing",
    data: [
      { img: "https://m.media-amazon.com/images/I/71BLkd39VKL._AC_SY170_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61SgADmAyDL._AC_SY170_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51EP887WiRL._AC_SY170_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71gSvhLIJZL._AC_SY170_.jpg" },
    ],
  },
  {
    type: "untitled",
    heading: "Gift ideas in Smartphones & Basic Mobiles",
    data: [
      { img: "https://m.media-amazon.com/images/I/61CeweKmlLL._AC_SY145_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61i+HI-zPwL._AC_SY145_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81kkRZALXQL._AC_SY145_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81X85GWtYHL._AC_SY145_.jpg" },
    ],
    hyperLinkTitle: "See more",
  },
  {
    type: "untitled",
    heading: "Customers’ Most-Loved Fashion for you",
    data: [
      { img: "https://m.media-amazon.com/images/I/616iBhe2roL._AC_SY145_.jpg" },
      { img: "https://m.media-amazon.com/images/I/614ZnW6FAiL._AC_SY145_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71eUwDk8z+L._AC_SY145_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61N9KZKj78L._AC_SY145_.jpg" },
    ],
    hyperLinkTitle: "Explore more",
  },
];

export const SinglePostersData = [
  {
    type: "single",
    heading: "Never before offers from Small businesses",
    img: "https://images-eu.ssl-images-amazon.com/images/G/31/Img24/SMB/GW/BAU/Desktop/Deals_CC_379x304_1x._SY304_CB580162467_.jpg",
    hyperLinkTitle: "See more",
  },
  {
    type: "single",
    heading: "Starting ₹99 | Start your fitness journey",
    img: "https://images-eu.ssl-images-amazon.com/images/G/31/img19/Sports/GW_Desktop/1199101_379x304_Compressed._SY304_CB448278349_.jpg",
    hyperLinkTitle: "See more",
  },
  {
    type: "single",
    heading: "Up to 60% off | Car, bike parts & accessories",
    img: "https://images-eu.ssl-images-amazon.com/images/G/31/2023/GateWay/December/CC/PC_379x304_1._SY304_CB572341311_.jpg",
    hyperLinkTitle: "See more",
  },
];

// ----------------------------------------------------------------- //

export const HomePostersData = [
  FullPostersData[0],
  FullPostersData[1],
  FullPostersData[2],
  FullPostersData[3],
  FullPostersData[4],
  FullPostersData[5],
  FullPostersData[6],
  FullPostersData[7],
];

// ----------------------------------------------------------------- //

export const HomePostersRow1 = [
  FullPostersData[8],
  FullPostersData[3],
  UntitledPostersData[0],
  FullPostersData[9],
];

// ----------------------------------------------------------------- //

export const HomePostersRow2 = [
  UntitledPostersData[1],
  FullPostersData[10],
  UntitledPostersData[0],
  FullPostersData[11],
];

// ----------------------------------------------------------------- //

export const HomePostersRow3 = [
  UntitledPostersData[2],
  SinglePostersData[0],
  SinglePostersData[1],
  SinglePostersData[2],
];

// ----------------------------------------------------------------- //

export const TodaysDealsData = [
  {
    detail: "Starting from 14999 including additional offers",
    discount: "25% off",
    price: { new: "₹14,999.00", old: "₹19,999.00" },
    img: "https://m.media-amazon.com/images/I/41snmTn9mrL._AC_SY200_.jpg",
  },
  {
    detail: "iQOO Z9 5G || Newly Launched @17999",
    discount: "Up to 20% off",
    img: "https://m.media-amazon.com/images/I/41WnWm3IjiL._AC_SY200_.jpg",
  },
  {
    detail: "Redmi 13C | Starting from 7799 including additional offers",
    discount: "Up to 37% off",
    img: "https://m.media-amazon.com/images/I/411iWoJEJjL._AC_SY200_.jpg",
  },
  {
    detail: "POCO C55 | Starting from 5999 including additional offers",
    discount: "54% off",
    price: { new: "₹6,499.00", old: "₹13,999.00" },
    img: "https://m.media-amazon.com/images/I/41ArHnIL7uL._AC_SY200_.jpg",
  },
  {
    detail: "iQOO 12 5G | India's only Flagship with 50MP @ 50999",
    discount: "Up to 12% off",
    img: "https://m.media-amazon.com/images/I/411gvmvNQzL._AC_SY200_.jpg",
  },
  {
    detail: "Redmi 12 5G | Starting from 11999 including additional offers",
    discount: "Up to 28% off",
    img: "https://m.media-amazon.com/images/I/41GsqdiCvOL._AC_SY200_.jpg",
  },
  {
    detail: "Starting from 22999 including additional offers",
    discount: "16% off",
    price: { new: "₹25,999.00", old: "₹30,999.00" },
    img: "https://m.media-amazon.com/images/I/412t8N-6ARL._AC_SY200_.jpg",
  },
  {
    detail: "Starting from 8999 including additional offers",
    discount: "39% off",
    price: { new: "₹8,499.00", old: "₹13,999.00" },
    img: "https://m.media-amazon.com/images/I/41dEcQR7UnL._AC_SY200_.jpg",
  },
  {
    detail: "POCO M6 Pro 5G | Starting from 9999 including additional offers",
    discount: "Up to 38% off",
    img: "https://m.media-amazon.com/images/I/41888-BWxKL._AC_SY200_.jpg",
  },
  {
    detail: "iQOO Z6 Lite | Best iQOO Smartphone @11999",
    discount: "40% off",
    price: { new: "₹11,999.00", old: "₹19,999.00" },
    img: "https://m.media-amazon.com/images/I/4105IiC5tDL._AC_SY200_.jpg",
  },
  {
    detail: "iQOO Neo 7 Pro | Powerful Smartphone @29999",
    discount: "Up to 23% off",
    img: "https://m.media-amazon.com/images/I/51o13K4h3-L._AC_SY200_.jpg",
  },
  {
    detail: "OnePlus 11R 5G",
    discount: "10% off",
    price: { new: "₹35,999.00", old: "₹39,999.00" },
    img: "https://m.media-amazon.com/images/I/41PMrVjsowL._AC_SY200_.jpg",
  },
];

// ----------------------------------------------------------------- //

export const productData = [
  {
    title: "Minimum 50% off | Unique products in home, kitchen & more",
    data: [
      { img: "https://m.media-amazon.com/images/I/81JSw5mE54L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71CqDrNy12L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/7123CDCSu7L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61oV9Lw0OKL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/910IWgT1KDL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/91X02MZ0CJL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81f+TPjeJPL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81x+7fce7VL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71a59eevl5L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51pxXEs93vL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81nsPEYKq+L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/518tiUZjw7L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71CJ3r85sIL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51jOkJhc8iL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41b9HP1fZEL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81w3KQ7smUL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41ojJX2KCeL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51V+nLI2MwL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61XzXq3wTWL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/31zOhh+ULvL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81sUj7-jivL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41J96-f3xkL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/811ds5vjKJL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/919fUMolGRL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51EXDKH9VcL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71r5IbBsG+L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61d0jOZcj8L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51gBMO+nIpL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61iPH6S3xUL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41ZMV9u3YgL._AC_SY200_.jpg" },
    ],
  },
  {
    title: "Best deals on women's fashion & accessories",
    data: [
      { img: "https://m.media-amazon.com/images/I/61a8wPsHvhL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61DJqNkhzvL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71ywC-J1M7L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61h+pZt4WWL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81Pb1isRLXL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61xQ3Wawr7L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71ahKlsuJRL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71oieQl4JrL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81hpITxSQyL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81Qu4hPgkQL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41QTMz8QVTL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71ahKlsuJRL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/519km6982xL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81mX+tadgBL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/6104rgX9r6L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51kW1psgCML._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/810Vje9DY8L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71E1aEzaX+L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71jvBcGeNWL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71fb7cJg6YL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71Q-hHySMnL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41IRSBgIjdL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61MalEYlJrL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71tLD68aifL._AC_SY200_.jpg" },
    ],
  },
  {
    title: "Up to 70% off | Top home products",
    data: [
      { img: "https://m.media-amazon.com/images/I/71ChK3xz-2L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/718TFCbEx4L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51QV-e0yocL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/816rXM6igSL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/617Dfnkd1NL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/21zs+107amL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/611uVnjYMlL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/617uKcjh1tL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71+HA3IeAzL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61HPmIxikhL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61Vj-zhzQGL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41x80SK7ArS._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71iTw5Yy7RL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/617nwCdNVnL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/518SjIzlOuL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81cr7ehFomL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61G7b-fi2hL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/91iDkZwuKPL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/91R2q0Ri2HL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61CDm9YddXL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71lGRGt1jhL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51ryrx3PqOL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/717UGXlBqrL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/419Yi0mA8VL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51oVKgW3+KL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71k8a-6dIdL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/718FNlHs0xL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71o7kaGk25L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61OI9FcZNjL._AC_SY200_.jpg" },
    ],
  },
  {
    title: "Up to 40% off | Top rated inverter batteries",
    data: [
      { img: "https://m.media-amazon.com/images/I/51qsB-hH5PL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61ataO-ZSWL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/617jYip3FtL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/6185JOcXn2L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/515CH-7knJL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/514t0KuEMgL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/617KhlKxwVL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61+39Lfuy4L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/519HIV3zPeL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/510kl9MhusL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61ViDx5G6+L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/31FTfu9-YmS._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41ytt-GA9QL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/515CH-7knJL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/515CH-7knJL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41Cq6s4-ZUS._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51+wfEiyjlL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41mSrhlthCL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51xNbHRcPwL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/413O46qCNEL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61hX79-gPXL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61OtwkAAS+S._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51ZgJW3EA-L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61441ooMGyS._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61PYqg6+TsL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61gtbjq7RYL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71FkWjG0-wL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51LbSKlWWjL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41iA9xy3eqL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71VykU0DZlL._AC_SY200_.jpg" },
    ],
  },
  {
    title: "Up to 65% off | Home furnishing curated from stores nearby",
    data: [
      { img: "https://m.media-amazon.com/images/I/71QGsGD2ZUL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81d5QvC0v6L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71q9F8ZalDL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71FiQ8q+6jL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41dDn6bij2L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/510lOFJnjcL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41Zzs-pBvOL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81iXQ1J5oNL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81So2UQD5BL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81Xjh6ROcSS._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81KqUE5K4BL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/715C0PGsTvL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/715ESu5x0gS._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/91imCRz0AnL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/81Q-Ab0M8ML._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/91DSgKApJHL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51whpDfarhL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/41R1VVu1HTL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/71q75hCnkIL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61vuLQaB46L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/512-55QG0cL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51KGdg2o0AL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/51-GynEzY8L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/711gq0bBFYL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/A1ZlCmGCFxL._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/91QIgnq8v3L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/31tzkuh8o5S._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/91lqLSTQe9L._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/91CCHb9elhS._AC_SY200_.jpg" },
      { img: "https://m.media-amazon.com/images/I/61GYzAf0ylL._AC_SY200_.jpg" },
    ],
  },
];
