import { useState } from "react";
import { Link } from "react-router-dom";

const EmiOptionsBtn = () => {
  const [emiExpanded, setEmiExpanded] = useState(false);
  return (
    <div className="cursor-pointer flex text-wrap flex-col text-sm border rounded w-full p-4">
      <div className="max-w-64 flex flex-col gap-4">
        <div
          className="cursor-pointer flex justify-between items-center gap-1"
          onClick={() => setEmiExpanded(!emiExpanded)}
        >
          <span>EMI Available</span>{" "}
          <img
            src={`https://img.icons8.com/ios/14/000000/expand-arrow.png`}
            alt="expand/collapse arrow"
          />
        </div>
        {emiExpanded && (
          <span>
            Your order qualifies for EMI with valid credit cards (not available
            on purchase of Gold, Jewelry, Gift cards and PsiaceStore pay balance top
            up).
            <Link className="text-primary-6" to={"/"}>
              Learn more
            </Link>
          </span>
        )}
      </div>
    </div>
  );
};
export default EmiOptionsBtn;
