import Poster from "./Poster";

const PosterCollection = ({ data: posters }) => {
  return (
    <div className="poster-cards grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-5">
      {posters.map((poster) => (
        <Poster key={JSON.stringify(poster)} data={poster} />
      ))}
    </div>
  );
};

export default PosterCollection;
